"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverwolfAllGamesResponseToJSON = exports.GetOverwolfAllGamesResponseFromJSONTyped = exports.GetOverwolfAllGamesResponseFromJSON = void 0;
function GetOverwolfAllGamesResponseFromJSON(json) {
    return GetOverwolfAllGamesResponseFromJSONTyped(json, false);
}
exports.GetOverwolfAllGamesResponseFromJSON = GetOverwolfAllGamesResponseFromJSON;
function GetOverwolfAllGamesResponseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetOverwolfAllGamesResponseFromJSONTyped = GetOverwolfAllGamesResponseFromJSONTyped;
function GetOverwolfAllGamesResponseToJSON(value) {
    return value;
}
exports.GetOverwolfAllGamesResponseToJSON = GetOverwolfAllGamesResponseToJSON;
