"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineResponse20019ToJSON = exports.InlineResponse20019FromJSONTyped = exports.InlineResponse20019FromJSON = void 0;
var _1 = require("./");
function InlineResponse20019FromJSON(json) {
    return InlineResponse20019FromJSONTyped(json, false);
}
exports.InlineResponse20019FromJSON = InlineResponse20019FromJSON;
function InlineResponse20019FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'users': (json['users'].map(_1.UserRankDataFromJSON)),
    };
}
exports.InlineResponse20019FromJSONTyped = InlineResponse20019FromJSONTyped;
function InlineResponse20019ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'users': (value.users.map(_1.UserRankDataToJSON)),
    };
}
exports.InlineResponse20019ToJSON = InlineResponse20019ToJSON;
