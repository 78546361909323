"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateProfileBodyToJSON = exports.UpdateProfileBodyFromJSONTyped = exports.UpdateProfileBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function UpdateProfileBodyFromJSON(json) {
    return UpdateProfileBodyFromJSONTyped(json, false);
}
exports.UpdateProfileBodyFromJSON = UpdateProfileBodyFromJSON;
function UpdateProfileBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, _1.PhoneNumberBodyFromJSON)(json['phoneNumber']),
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'ordersEmail': !(0, runtime_1.exists)(json, 'ordersEmail') ? undefined : json['ordersEmail'],
        'ordersPhoneNumber': !(0, runtime_1.exists)(json, 'ordersPhoneNumber') ? undefined : (0, _1.PhoneNumberBodyFromJSON)(json['ordersPhoneNumber']),
        'ordersFirstName': !(0, runtime_1.exists)(json, 'ordersFirstName') ? undefined : json['ordersFirstName'],
        'ordersLastName': !(0, runtime_1.exists)(json, 'ordersLastName') ? undefined : json['ordersLastName'],
        'silentDupeChecks': !(0, runtime_1.exists)(json, 'silentDupeChecks') ? undefined : json['silentDupeChecks'],
    };
}
exports.UpdateProfileBodyFromJSONTyped = UpdateProfileBodyFromJSONTyped;
function UpdateProfileBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'phoneNumber': (0, _1.PhoneNumberBodyToJSON)(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'ordersEmail': value.ordersEmail,
        'ordersPhoneNumber': (0, _1.PhoneNumberBodyToJSON)(value.ordersPhoneNumber),
        'ordersFirstName': value.ordersFirstName,
        'ordersLastName': value.ordersLastName,
        'silentDupeChecks': value.silentDupeChecks,
    };
}
exports.UpdateProfileBodyToJSON = UpdateProfileBodyToJSON;
